import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const TRAINING_ENDPOINTS = {
  base: `${BASE_URL}api/training/`,
  home: `${BASE_URL}api/training/home/`,
  get: (id) => `${BASE_URL}api/training/get/${id}/`,
  getTrainings : `${BASE_URL}api/training/get/`,
  join: (id) => `${BASE_URL}api/training/join/${id}/`,
  unjoin: (id) => `${BASE_URL}api/training/unjoin/${id}/`,
  status : (id) => `${BASE_URL}api/training/status/${id}/`,
  delete: `${BASE_URL}api/training/delete/`,
  groups: `${BASE_URL}api/training/groups/`,
  trainers: `${BASE_URL}api/training/trainers/`,
  sessions:  `${BASE_URL}api/training/sessions`,
  sessionDelete: `${BASE_URL}api/training/session/delete/`,
  updateAttendance: `${BASE_URL}api/training/sessions/attendance/`
};

class Training {
  // Create Training
  /*
  name (string)
  group (string)
  description (string)
  min_attendance (integer)
  max_attendance (integer)

  sessions (array): Array of session objects
    session object: { order (int), name (string), modules (array of module objects) }
      module object: { module_id (int), date (string), time (string), duration (int), type (string), order (int), trainer (string), note (string)}

  users (array): Array of strings
  levels (array): Array of strings
  roles (array): Array of strings
   */
  async create_training({
    name,
    group,
    description,
    min_attendance,
    max_attendance,
    sessions,
    color,
    users,
    levels,
    roles,
    status
  }) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.base,
      method: "POST",
      body: {
        name,
        group,
        description,
        color,
        min_attendance,
        max_attendance,
        sessions,
        users,
        levels,
        roles,
        status
      },
    });
    return { data, res };
  }


  // Edit Training
  /* 
  training_id (int)
  name (string)
  group (string)
  description (string)
  min_attendance (integer)
  max_attendance (integer)
  users (array): Array of strings
  levels (array): Array of strings
  roles (array): Array of strings
  */
  async edit_training({
    training_id,
    name,
    group,
    description,
    min_attendance,
    max_attendance,
        color,
        sessions,
    users,
    levels,
    roles,
    status
  }) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.base,
      method: "PUT",
      body: {
        training_id,
        name,
        group,
        description,
        color,
        min_attendance,
        max_attendance,
        sessions,
        users,
        levels,
        roles,
        status
      },
    });
    return { data, res };
  }


  // Delete Training
  async delete_training(training_ids ) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.delete,
      method: "DELETE",
      body: {
        training_ids,
      },
    });
    return { data, res };
  }


  // join training session
  /* 
    training_id (string)
    session_id (string)
  */
  async Join_training({ training_id, session_id,users}) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.join(training_id),
      method: "POST",
      body: {
        session_id,
        users
      },
    });
    return { data, res };
  }

  async unJoin_training({ training_id, session_id}) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.unjoin(training_id),
      method: "POST",
      body: {
        session_id,
      },
    });
    return { data, res };
  }


  // change training status
  async training_status(training_id) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.status(training_id),
      method: "GET",
    });
    return { data, res };
  }

  
  // Get Trainings with pagination
  async get_trainings({ page, count, groups, statuses, title, description, module}) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.getTrainings,
      method: "POST",
      body: {
        page,
        count,
        groups,
        statuses,
        title,
        description,
        module
      },
    });
    return { data, res };
  }


  // Get Trainings For home
  async get_home_trainings() {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.home,
      method: "GET",
    });
    return { data, res };
  }


  // Get Next Trainings For home
  async get_home_trainings_next({page, group, count = 10}) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.home,
      method: "POST",
      body: {
        page,
        count,
        group
      },
    });
    return { data, res };

  }


  // Get Sessions
  async get_sessions( page, count,filters ) {
    console.log(page,count)
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.sessions,
      method: "POST",
      params: {
        page,
        count,
      },
      body:{
        page,
        count,
        filters
      }
    });
    return { data, res };
  }

  // Get Training Groups
  async get_groups() {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.groups,
      method: "GET",
    });
    return { data, res };
  }


  // Get trainers list
  /* 
    For now it will return all users of that org since we don't have the trainer role yet
  */
  async get_trainers() { 
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.trainers,
      method: "GET",
    });
    return { data, res };
  }



  // Session delete by id
  /* 
    session_id (str)
    training_id (str)
  */
  async delete_session(session_id, training_id ) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.sessionDelete,
      method: "DELETE",
      body: {
        session_id,
        training_id,
      },
    });
    return { data, res };
  }


  async updateAttendance({training_id, list}) {
    const { data, res } = await request({
      url: TRAINING_ENDPOINTS.updateAttendance,
      method: "POST",
      body: {
        training_id,
        list
      },
    });
    return { data, res };
  }
}

export const TrainingService = new Training();