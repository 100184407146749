<template>
  <CCard class="bg-transparent border-0 shadow-none">
    <CCardBody class="">
      <!-- <CCardText class="card-title">{{ $t("My status") }}</CCardText> -->
      <div class="status-container">
        <div
          class="d-flex flex-column gap-2 align-item-center justify-content-center text-center"
          v-for="status in statusList"
          :key="status.id"
        >
          <p
            class="mb-0"
            style="
              font-size: 16px !important;
              font-weight: 700 !important;
              color: #434343 !important;
              white-space: nowrap !important;
            "
          >
            {{ $t("My action plan") }}
          </p>
          <div class="donut mr-2">
            <el-progress
              type="circle"
              class="custom-progress"
              :color="colors"
              :percentage="
                userPoints
                  ? Math.round(calculateProgress(userStore.usersProgress)) == 0
                    ? 1
                    : Math.round(calculateProgress(userStore.usersProgress))
                  : 1
              "
            >
              <template #default="{ percentage }">
                <span
                  class="percentage-value"
                  :style="
                    'color :' +
                    getFillColor(
                      Math.round(getProgressValue(userData.total_points))
                    )
                  "
                  >{{ Math.round(getProgressValue(userData.total_points)) }}%
                </span>
              </template>
            </el-progress>
            <!-- <CircleProgress
              :percent="userPoints? calculateProgress(userPoints) : 0"
              :viewport="true"
              :show-percent="false"
              :fill-color="getFillColor(calculateProgress(userPoints))"
              class="donut-chart"
              style="color: #ccc"
              size="90"
              border-width="10"
              border-bg-width="10"
            /> -->
            <!-- <div class="donut-text">
              {{ this.loading ? "" : $t(status.name) }}
              
            </div> -->
          </div>
          <!-- <div class="d-flex flex-column justify-content-center ">
            <h3 class="mb-0">
              {{ userPoints? Math.round(calculateProgress(userPoints)) : 0 }}%
            </h3>
            
          </div> -->
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { UsersService } from "@/services/users";
import { ContentLoader } from "vue-content-loader";
import { mapGetters, mapState } from "vuex";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "CardStatusMe",
  components: {
    CircleProgress,
    ContentLoader,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      userPoints: (state) => state.userData.total_points,
    }),
  },
  data() {
    return {
      userStore: useUserStore(),
      colors: [
        { color: "#E40303", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#2EB85C", percentage: 100 },
      ],
      showOptions: false,
      loading: true,
      selectedTeam: null,
      teams: [],
      statusList: [
        {
          id: 1,
          name: "Actions plan",
        },
      ],
    };
  },
  watch: {},

  async created() {
    const user = this.userData;

    const actualUserPoints = parseFloat(user.total_points.split("/")[0].trim());
    const totalUserPoints = parseFloat(user.total_points.split("/")[1].trim());

    if (totalUserPoints === 0) this.actionsPercentage = 0;
    else this.actionsPercentage = (actualUserPoints / totalUserPoints) * 100;
    this.teams = user.average_teams_advancement;
    this.selectedTeam = this.teams[0].organization_name;

    this.loading = false;
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },

    getFillColor(share) {
      if (share < 20) {
        return "#E40303";
      } else if (share < 40) {
        return "#e6a23c";
      } else if (share < 60) {
        return "#5cb87a";
      } else if (share < 80) {
        return "#1989fa";
      } else if (share < 100 || share == 100) {
        return "#2EB85C";
      }
    },
    calculateProgress(str) {
      if (typeof str !== "string" || str.trim() === "") {
        return 0;
      }
      const parts = str.split(" / ");
      if (parts.length === 2) {
        const current = parseFloat(parts[0]);
        const total = parseFloat(parts[1]);
        if (total === 0) {
          return 0;
        }

        const percentage = (current / total) * 100;
        return percentage;
      }
      return 0;
    },
    getProgressValue(userPoints) {
      const [currentPoints, totalPoints] = userPoints.split("/").map(Number);
      if (currentPoints === 0) return 0;

      const progress = (currentPoints / totalPoints) * 100;
      return progress;
    },
  },
};
</script>

<style scoped>
.orgProjectOption {
  padding: 5px;
  cursor: pointer;
}
.orgProjectOption:hover {
  background: #f5f5f5;
}
.orgProjectOption:active {
  background: #e6e6e6;
}
.status-container {
  /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 20px; */
  gap: 20px;
}
.donut {
  margin-top: 5px;
}
.donut-text {
  bottom: 30%;
  text-align: center;
}
.donut-chart {
  color: green;
  position: absolute;
}
.percentage-value {
  font-weight: 700;
}
::v-deep .el-progress-circle {
  width: 90px !important;
  height: 90px !important;
}

::v-deep .card-body {
  padding: 3px 0px !important;
}

::v-deep .custom-progress .el-progress-circle__track {
  stroke: #e9e9e9;
}
</style>
