<template class="h-100">
  <CCard class="h-100">
    <CCardBody>
      <CCardText class="card-title" v-if="$route.name == 'Home'">{{
        $t("News")
      }}</CCardText>
      <div v-if="newsLoading" class="card-news">
        <el-skeleton style="height: 100%; padding: 35px" animated>
          <template #template a>
            <el-skeleton-item
              variant="image"
              style="width: 100%; height: 80%"
            />
            <div style="padding: 8px 0; height: 20%">
              <el-skeleton-item variant="p" style="width: 50%" />
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-items: space-between;
                "
              >
                <el-skeleton-item variant="text" style="margin-right: 16px" />
                <el-skeleton-item variant="text" style="width: 30%" />
              </div>
            </div>
            <div style="display: flex; justify-content: space-between">
              <el-skeleton-item variant="text" style="width: 10%" />
              <el-skeleton-item variant="text" style="width: 10%" />
            </div>
          </template>
        </el-skeleton>
      </div>
      <div v-else class="card-news">
        <div
          v-if="activeCommunications.length"
          style="height: 100%; display: flex; flex-direction: column"
        >
          <el-carousel
            trigger="click"
            :interval="interval"
            :ref="'carousel'"
            :arrow="'never'"
            :indicator-position="'none'"
            @change="handleSlideChange"
            class="home--news"
          >
            <el-carousel-item
              v-for="(communication, index) in activeCommunications"
              :key="item"
            >
              <router-link :to="`news/${communication.id}`" class="router-link">
                <div class="router-link">
                  <div
                    class="carousel__item"
                    :name="'carousel__item' + index"
                    :key="'carousel__item' + index"
                  >
                    <div class="image-container">
                      <img
                        :src="getImageSrc(communication.image_id)"
                        alt="News Image"
                        class="communication-image"
                      />
                      <!-- style="height: 100%; width: 100%; object-fit: cover" -->
                    </div>
                    <h4 class="mt-3 slide-title">
                      {{ truncateString(communication.name) }}
                    </h4>
                    <p>
                      {{
                        truncateString(
                          communication.description
                            .replace(/<[^>]*>/g, "")
                            .slice(0, 100)
                        )
                      }}
                    </p>
                  </div>
                </div>
              </router-link>
            </el-carousel-item>
          </el-carousel>
          <div
            class="d-flex align-items-center justify-content-between"
            style="border-top: 1px solid rgb(195, 195, 195); padding: 15px 30px"
            v-if="this.activeCommunications.length"
          >
            <button @click="this.$refs.carousel.prev()" class="navigation__btn">
              <i class="fa fa-chevron-left"></i> {{ $t("Back") }}
            </button>
            <div class="custom-indicators">
              <button
                v-for="(communication, index) in activeCommunications"
                :key="index"
                :class="{ active: index === currentIndex }"
                @click="goToSlide(index)"
              >
                <svg
                  style="width: 13px"
                  v-if="index === currentIndex"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="4" cy="4" r="4" fill="#0071CE" />
                </svg>

                <svg-icon type="mdi" :path="circleOutline" v-else></svg-icon>
              </button>
            </div>
            <button
              @click="() => this.$refs.carousel.next()"
              class="navigation__btn"
            >
              {{ $t("Next") }} <i class="fa fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <div v-else class="card-news">
          <el-empty
            :description="$t('No news available')"
            style="margin: auto"
          />
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { UsersService } from "@/services/users";
import { ContentLoader } from "vue-content-loader";
import { mapGetters, mapState } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCircleOutline, mdiCircleSlice8, mdiCircleSmall } from "@mdi/js";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "CardNews",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ContentLoader,
    SvgIcon,
  },
  data() {
    return {
      userStore: useUserStore(),
      orgName: "",
      circleOutline: mdiCircleOutline,
      CircleSlice8: mdiCircleSmall,
      interval: 5000, // Interval between slides in milliseconds

      currentIndex: 0, // Current index of the active slide
    };
  },
  props: {
    communications: Array,
    newsLoading: Boolean,
  },
  created() {
    // this.getActiveUser()
    // this.getCommunicationList();
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      currentOrganizationObj: (state) => state.orgObj,
    }),
    activeCommunications() {
      return this.communications;
    },
    wrapAround() {
      return this.activeCommunications.length > 1;
    },
    autoplayValue() {
      return this.activeCommunications.length > 0 ? 3000 : false;
    },
    activeC() {
      return this.$refs.Carousel?.data?.currentSlide;
    },
  },
  mounted() {},
  methods: {
    changeSlide(index) {
      this.activeSlide = index;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(String("carousel__item" + index));
    },
    goToSlide(index) {
      this.currentIndex = index;
      this.$refs.carousel && this.$refs.carousel.setActiveItem(index);
    },
    handleSlideChange(index) {
      this.currentIndex = index;
    },
    getImageSrc(image_id) {
      const myBlobToken = this.userStore.blobToken;
      return `https://stmzgdsengage.blob.core.windows.net/news/${image_id}?${myBlobToken}`;
    },

    truncateString(str) {
      if (str.length > 50) {
        return str.substring(0, 50) + "...";
      } else {
        return str;
      }
    },
  },
};
</script>

<style scoped>
.card-body {
  padding: 0px;
  display: flex;
  flex-direction: column;
}
.content_width {
  flex: 0 1 65%;
  height: 100%;
}
.card-title {
  padding: 20px 30px;
  padding: 20px 30px;
  height: 25px;
}
</style>
<style>
.home--news.el-carousel--horizontal {
  overflow: hidden;
  /* height: 80%; */
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 999999;
}
.home--news .el-carousel__container {
  height: 100%;
}
.card-title {
  font-weight: bold;
  font-size: 25px;
}
.carousel__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  /* height: calc(100vh - 230px); */
  height: 100%;
  padding: 15px 0;
}
.carousel__item h4,
p {
  text-decoration: none;
  color: #434343;
}
.router-link {
  text-decoration: none !important;
  color: #434343; /* Change to your desired color */
}
.carousel__slide {
  justify-content: flex-start !important;
  align-items: normal;
}
.carousel {
  text-align: left;
}
.carousel__pagination-button::after {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: #03164d;
}
.carousel__pagination {
  margin: auto;
}
button:focus {
  outline: none;
}
.image-container {
  max-width: 100%;
  height: 600px;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
}
.communication-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.card-news {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.navigation__btn {
  background: none;
  border: none;
  gap: 10px;
  display: flex;
  align-items: center;
  z-index: 1;
  color: black;
  font-weight: 600;
}
.navigation__btn i {
  color: black;
}
.carousel__prev,
.carousel__next {
  bottom: -3%;
  top: unset;
}
.custom-indicators button {
  background-color: none;
  border: none;
  cursor: pointer;
  display: flex;
  background: none;
  height: 16px;
  padding: 0;
}
.custom-indicators button svg {
  width: 100%;
  height: 100%;
  color: #0071ce;
}
/*
.custom-indicators button.active {
  background-color: #333; */
/* } */
.carousel__pagination,
.custom-indicators {
  bottom: -24px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
}
.home--news .carousel__pagination-button::after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 1px solid #03164d;
  background: transparent;
}
.home--news .carousel__pagination-button:hover::after,
.home--news .carousel__pagination-button--active::after {
  background-color: #03164d !important;
}
.home--news .el-carousel__item .router-link {
  height: 100%;
}

@media (max-width: 974px) {
  .card-news {
    width: auto;
    height: calc(100vh - 400px);
    /* margin-bottom: 65px; */
  }
  .carousel__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    /* height: calc(100vh - 230px); */
    height: 100%;
  }
  .home--news .el-carousel__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: hidden;
    z-index: calc(var(--el-index-normal) - 1);
  }

  .image-container {
    /* max-width: 100%; */
    height: 70%;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .home--news .el-carousel__container {
    height: 100% !important;
  }
}
</style>
