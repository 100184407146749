import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const CHALLENGES_ENDPOINTS = {
  create_challenge: `${BASE_URL}api/challenges/`,
  get_challenges: `${BASE_URL}api/challenges/`,
  get_limited_challenges: (page, size) =>
    `${BASE_URL}api/challenges/?page=${page}&limit=${size}`,
  get_challenge: (id) => `${BASE_URL}api/challenges/${id}`,
  update_challenge: (id) => `${BASE_URL}api/challenges/${id}`,
  set_challenge_active: `${BASE_URL}api/challenges/set_challenge_active`,
  award_users: (id) => `${BASE_URL}api/challenges/award/${id}`,
  award_user: (challengeId, userId) =>
    `${BASE_URL}api/challenges/award/${challengeId}/${userId}`,
  remove_challenges: `${BASE_URL}api/challenges/`,
};

class Challenges {
  async createChallenge({
    challengeData,
    levels = [],
    users = [],
    roles = [],
  }) {
    const { data, res } = await request({
      url: CHALLENGES_ENDPOINTS.create_challenge,
      method: "POST",
      skipRedirect: true,
      body: { data: challengeData, levels, users, roles },
    });
    return { data, res };
  }

  async getChallenges(page = null, size = null) {
    const { data, res } = await request({
      url:
        page && size
          ? CHALLENGES_ENDPOINTS.get_limited_challenges(page, size)
          : CHALLENGES_ENDPOINTS.get_challenges,
      method: "GET",
      skipRedirect: true,
    });
    return { data, res };
  }

  async getChallenge(id) {
    const { data, res } = await request({
      url: CHALLENGES_ENDPOINTS.get_challenge(id),
      method: "GET",
      skipRedirect: true,
    });
    return { data, res };
  }

  async updateChallenge({
    id,
    challengeData,
    users = [],
    levels = [],
    roles = [],
  }) {
    const { data, res } = await request({
      url: CHALLENGES_ENDPOINTS.update_challenge(id),
      method: "PUT",
      skipRedirect: true,
      body: { data: challengeData, levels, users, roles },
    });
    return { data, res };
  }
  async set_challenge_active({ _id, active }) {
    var { data, res } = await request({
      url: CHALLENGES_ENDPOINTS.set_challenge_active,
      method: "POST",
      skipRedirect: true,
      body: {
        _id,
        active,
      },
    });
    return { data, res };
  }
  async awardUsers(challengeId, users) {
    const { data, res } = await request({
      url: CHALLENGES_ENDPOINTS.award_users(challengeId),
      method: "PUT",
      skipRedirect: true,
      body: { data: { users } },
    });
    return { data, res };
  }

  async awardUser(challengeId, userId) {
    const { data, res } = await request({
      url: CHALLENGES_ENDPOINTS.award_user(challengeId, userId),
      method: "POST",
      skipRedirect: true,
    });
    return { data, res };
  }

  async removeChallenge(ids) {
    const { data, res } = await request({
      url: CHALLENGES_ENDPOINTS.remove_challenges,
      method: "DELETE",
      skipRedirect: true,
      body: { ids: ids },
    });
    return { data, res };
  }
}

export const ChallengeService = new Challenges();
