<template>
  <div>
    <div class="main-content">
      <Cards
        :communications="communications"
        :allEvents="allEvents"
        :trainings="trainings"
        :events="events"
        :newsLoading="newsLoading"
        :eventsLoading="eventsLoading"
      />
    </div>
  </div>
</template>

<script>
import "vue3-circle-progress/dist/circle-progress.css";
import { images } from "../../assets/Images/images";
import Cards from "./components/Cards.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { NewsService } from "@/services/news";
import { AgendaService } from "@/services/agenda";
import { useGlobalStore } from "@/stores/store";

import { mapState } from "vuex";
import { ElLoading } from "element-plus";

export default {
  name: "Home",
  components: {
    Cards,
    OrganizationSelector,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      currentOrganizationObj: (state) => state.orgObj,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
    }),
  },
  data() {
    return {
      globalStore: useGlobalStore(),
      communications: [],
      allEvents: [],
      trainings: [],
      events: [],
      userName: "",
      email: "",
      newsLoading: true,
      eventsLoading: true,
    };
  },

  async created() {
    this.globalStore.setEventsAreLoading(true);
    this.refreshEverything();
  },
  methods: {
    async refreshEverything() {
      // const loading = ElLoading.service({
      //   fullscreen: true,
      //   background: "#0a1f8f45",
      // });
      await this.getCommunicationList();
      await this.getActiveUser();
      await this.getEvents();
      // loading.close();
    },
    setNeedToReload() {
      this.needToReload = false;
    },
    async getCommunicationList() {
      try {
        // this.loading = true;

        const { data } = await NewsService.get_news({
          page: 1,
          count: 10,
        });
        this.communications = data.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        this.communications = this.communications.filter((comm) => {
          return (
            comm.users && comm.featured && comm.users.includes(this.userData.id)
          );
        });
      } catch (error) {
        console.error("Error fetching News data:", error);
        this.newsLoading = false;
      } finally {
        // this.loading = false;
        this.newsLoading = false;
      }
    },
    async getEvents() {
      const currentDate = new Date();
      const yesterday = new Date();
      yesterday.setDate(currentDate.getDate() - 1);
      this.globalStore.setEventsAreLoading(true);

      try {
        const { data } = await AgendaService.list_events(true);
        this.allEvents = data.data;

        const parseDateTime = (event) => {
          const [startTime] = event.time.split(" - ");
          return new Date(`${event.date}T${startTime}`);
        };

        // Sorting by date and time in descending order
        this.allEvents.sort((a, b) => parseDateTime(b) - parseDateTime(a));

        this.trainings = this.allEvents.filter(
          (training) =>
            Array.isArray(training.participants) &&
            training.participants.includes(this.userName) &&
            training.type === "training" &&
            training.project === this.currentProject.name &&
            (training.organization === this.currentOrganization.name ||
              training.organization === this.currentOrganization.organization ||
              training.organization === this.currentOrganization) &&
            new Date(training.date) >= yesterday
        );

        this.events = this.allEvents.filter(
          (event) =>
            Array.isArray(event.participants) &&
            event.participants.includes(this.userData.id) &&
            event.type !== "training" &&
            event.project === this.currentProject.name &&
            (event.organization === this.currentOrganization.name ||
              event.organization === this.currentOrganization.organization ||
              event.organization === this.currentOrganization) &&
            new Date(event.date) >= yesterday
        );

        if (this.events.length === 0) {
          // If no future or current events, show the last 3 past events
          const pastEvents = this.allEvents.filter(
            (event) =>
              Array.isArray(event.participants) &&
              event.participants.includes(this.userData.id) &&
              event.type !== "training" &&
              event.project === this.currentProject.name &&
              (event.organization === this.currentOrganization.name ||
                event.organization === this.currentOrganization.organization ||
                event.organization === this.currentOrganization) &&
              new Date(event.date) < yesterday
          );

          pastEvents.sort((a, b) => parseDateTime(b) - parseDateTime(a));
          this.events = pastEvents.slice(0, 3);
        }
      } catch (error) {
        console.error("Error fetching events", error);
      } finally {
        this.eventsLoading = false;
        this.globalStore.setEventsAreLoading(false);
      }
    },
    async getActiveUser() {
      this.userName = this.userData.fullname;
      this.email = this.userData.email;
    },
  },
};
</script>

<style scoped>
.my-page {
  display: flex;
}

.main-content {
  /* max-height: calc(100% - 120px); */
  overflow-y: auto;
  margin: auto;
  padding-right: 8px;
  height: calc(100vh - 110px);
}

@media (max-width: 768px) {
  .main-content {
    padding: 0px;
  }
}

@media (max-width: 1133px) {
  .main-content {
    max-height: calc(100vh - 110px);
  }
}
@media (max-width: 970px) {
  .main-content {
    margin-top: 10px;
    /* max-height: 80vh; */
    overflow: auto;
  }
}
</style>
