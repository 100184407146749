import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const SUPPORT_ENDPOINTS = {
  get_news: `${BASE_URL}api/news/all/`,
  create_news: `${BASE_URL}api/news/`,
  update_news: `${BASE_URL}api/news/`,
  delete_news: `${BASE_URL}api/news/delete/`,
  get_specific_news: (id) => `${BASE_URL}api/news/detail/${id}/`,
};

class News {
  async get_news(payload) {
    /* 
    payload: {
      "page": 1,
      "count": 10,
    }
    */
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.get_news,
      method: "POST",
      body: payload,
    });
    return {
      data,
      res,
    };
  }

  get_specific_news = async (id) => {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.get_specific_news(id),
      method: "GET",
    });
    return {
      data,
      res,
    };
  };

  async create_news(payload) {
    /* 
      formData should have below fields:
      name: str 
      description: str 
      active: bool 
      featured: bool (Ignore it and send it always as true)
      delta: str 
      image_id: str 
      image_name: str 
      roles: Optional[list]
      levels: Optional[list]
      users: Optional[list]
    */
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.create_news,
      method: "POST",
      body: payload,
    });
    return {
      data,
      res,
    };
  }

  async update_news(body) {
    /* 
      payload should have below fields:
      id: str
      name: str 
      description: str 
      active: bool 
      featured: bool (Ignore it and send it always as true)
      delta: str = 
      image_id: str 
      image_name: str 
      users: Optional[list] 
      roles: Optional[list] 
      levels: Optional[list]
    */
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.update_news,
      method: "PUT",
      body: body,
    });
    return {
      data,
      res,
    };
  }

  async delete_news(ids) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.delete_news,
      method: "DELETE",
      body: ids,
    });
    return {
      data,
      res,
    };
  }
}

export const NewsService = new News();
