<template class="h-100">
  <CCard class="h-100">
    <CCardBody class="h-100">
      <CCardText class="card-title">{{ $t("Events and trainings") }}</CCardText>
      <div class="overflow-scroll" style="height: 250px;">
        <ContentLoader
          viewBox="0 0 400 80"
          v-if="loading"
          :speed="2"
          primaryColor="rgb(230, 230, 230)"
          secondaryColor="rgb(240, 240, 240)"
          v-for="index in 2"
          :key="index"
        >
          <rect x="10" y="10" rx="5" ry="5" width="9" height="60" />
          <rect x="20" y="10" rx="3" ry="3" width="350" height="60" />
        </ContentLoader>
        <!-- Actual Event Cards -->
        <div v-else-if="trainings.length > 0">
          <div
            v-for="(training, index) in trainings"
            :key="index"
            style="
              background-color: #E7F0FA;
              margin-bottom: 10px;
              border-radius: 5px;
              padding: 2px 10px;
              height: fit-content;
              display: flex;
            "
          >
            <div
              style="
                margin: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
              "
            >
            <i style="color: #3399FF;font-size: x-large;" :class="training.type && training.type=='training'?'fa fa-graduation-cap':'fa fa-calendar'" ></i>
          </div>
          
            <div
              style="width: 100%; cursor: pointer"
              @click="goToEvent(training)"
            >
              <div class="d-flex justify-content-between">
                <h5 class="pl-3 mt-2" style="font-size: 17px;font-weight: 540;" >{{ training.title }}</h5>
              </div>

              <div class="d-flex justify-content-between" style="font-size: small;color: grey;">
                <span class="pl-3">{{ training.date }}</span>
                <span class="pr-3">{{ training.time.split(" ")[0] }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Message when no events are available -->
        <div v-else>
          <p>{{ $t("No training available") }}.</p>
        </div>
      </div>
      <CModal
        :show="isConfirmOpen"
        @hide="closeConfirmModal"
        :top="true"
        :draggable="false"
      >
        <template v-slot:header>
          <h3>{{ $t("Confirm Attending") }}</h3>
          <CButton @click="closeConfirmModal">
            <i class="fa fa-times"></i>
          </CButton>
        </template>
        <template v-slot:footer>
          <CButton @click="closeConfirmModal" class="link">{{
            $t("Cancel")
          }}</CButton>
          <CButton @click="confirmAttending" class="modal-confirm" color="info">
            {{ $t("Confirm") }}
          </CButton>
        </template>
        <p>Are you sure to attend this training ?</p>
      </CModal>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapMutations } from "vuex";
import { ContentLoader } from "vue-content-loader";
import { UsersService } from "@/services/users";
import { TrainingService } from "@/services/training";
import { showMessage } from "../../../../utils/helpers";
import { mapGetters, mapState } from "vuex";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCalendarMonth } from '@mdi/js';
import { mdiMedal } from '@mdi/js';

export default {
  name: "CardTraining",
  components: {
    SvgIcon,
    ContentLoader,
  },
  props: {
    needToReload: Boolean,
  },
  data() {
    return {
      isConfirmOpen: false,
      trainings: [],
      CalendarMonth: mdiCalendarMonth,
      Medal: mdiMedal,

      loading: true,
      email: "",
      check: false,
      organization: "",
      project: "",
      label: "",
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
    }),
  },
  async created() {
    await this.getActiveUser();
    await this.getListTraining();
  },

  methods: {
    refreshEverything() {
      this.getListTraining();
    },
    openConfirmModal(training) {
      (this.organization = training.organization),
        (this.project = training.project),
        (this.label = training.title);
      this.isConfirmOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmOpen = false;
    },
    async confirmAttending() {
      const { data, res } = await TrainingService.user_attend({
        organization: this.organization,
        project: this.project,
        email: this.email,
        check: !this.check,
        label: this.label,
      });
      if (res.status === 200) {
        showMessage("success", "Attendance confirmed successfully!");
      } else {
        showMessage("error", "Failed to confirm attendance!");
      }
      this.closeConfirmModal();
      this.getListTraining();
    },
    ...mapMutations(["updateSelectedTraining"]),
    goToEvent(training) {
      this.updateSelectedTraining(training);
      this.$router.push("/agenda");
    },
    async getActiveUser() {
      this.email = this.userData.email;
    },

    async getListTraining() {
      const { data, res } = await TrainingService.list_training_home({
        email: this.email,
      });
      this.trainings = data.data;
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.modal-confirm {
  width: 100px;
  border-radius: 0px 0px 0px 25px;
}
</style>
