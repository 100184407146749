<template>
  <CCard class="card_event h-100" style="overflow: hidden">
    <CCardBody
      class="d-flex flex-column card-event-body"
      :style="[
        'gap: 12px',
        'overflow: hidden',
        events.length > 0 ? 'height: fit-content  ' : '',
        'padding: 20px 25px;',
      ]"
    >
      <span class="card-title h-20" v-if="isEvents">{{ $t("Events") }}</span>
      <span class="card-title h-20" v-else>{{ $t("Challenges") }}</span>
      <div class="card--event--container">
        <div v-if="globalStore.eventsAreLoading" class="h-100">
          <el-skeleton :rows="5" animated class="h-100">
            <template #template a>
              <el-skeleton-item
                v-for="(item, index) in 3"
                :key="index"
                style="height: calc(100% / 3 - 10px)"
              />
            </template>
          </el-skeleton>
        </div>
        <div
          v-if="events.length > 0 && !globalStore.eventsAreLoading && isEvents"
          class="d-flex flex-column"
          style="display: none"
        >
          <div
            v-for="(event, index) in events"
            :key="index"
            style="display: flex"
            class="event-card"
          >
            <div
              style="
                margin: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
              "
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.9375 0C4.08668 0 4.22976 0.0592632 4.33525 0.164752C4.44074 0.270242 4.5 0.413316 4.5 0.5625V1.125H13.5V0.5625C13.5 0.413316 13.5593 0.270242 13.6648 0.164752C13.7702 0.0592632 13.9133 0 14.0625 0C14.2117 0 14.3548 0.0592632 14.4602 0.164752C14.5657 0.270242 14.625 0.413316 14.625 0.5625V1.125H15.75C16.3467 1.125 16.919 1.36205 17.341 1.78401C17.7629 2.20597 18 2.77826 18 3.375V15.75C18 16.3467 17.7629 16.919 17.341 17.341C16.919 17.7629 16.3467 18 15.75 18H2.25C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75V3.375C0 2.77826 0.237053 2.20597 0.65901 1.78401C1.08097 1.36205 1.65326 1.125 2.25 1.125H3.375V0.5625C3.375 0.413316 3.43426 0.270242 3.53975 0.164752C3.64524 0.0592632 3.78832 0 3.9375 0V0ZM2.25 2.25C1.95163 2.25 1.66548 2.36853 1.4545 2.5795C1.24353 2.79048 1.125 3.07663 1.125 3.375V4.5H16.875V3.375C16.875 3.07663 16.7565 2.79048 16.5455 2.5795C16.3345 2.36853 16.0484 2.25 15.75 2.25H2.25ZM16.875 5.625H1.125V15.75C1.125 16.0484 1.24353 16.3345 1.4545 16.5455C1.66548 16.7565 1.95163 16.875 2.25 16.875H15.75C16.0484 16.875 16.3345 16.7565 16.5455 16.5455C16.7565 16.3345 16.875 16.0484 16.875 15.75V5.625Z"
                  fill="#434343"
                />
              </svg>
            </div>
            <div style="width: 100%" @click="goToEvent(event)">
              <p class="m-0" style="font-weight: 600; font-size: 15px">
                {{ event.title }}
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <span class="">{{ event.date }}</span>
                <span class="pr-0">{{ event.time.split(" ")[0] }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="
            !isEvents &&
            activeChallenges.length > 0 &&
            !globalStore.eventsAreLoading
          "
          class="d-flex flex-column h-100"
          style="display: none"
        >
          <div
            v-for="(challenge, index) in activeChallenges"
            :key="index"
            style="display: flex"
            class="event-card"
            @click="goToChallenge(challenge)"
          >
            <div
              style="
                margin: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
              "
            >
              <div
                justify="center"
                class="svg-container cardBadge"
                v-html="getBadgeSvg(challenge.badge)"
                :class="{
                  bronze_badge: challenge.color === 'Bronze',
                  silver_badge: challenge.color === 'Silver',
                  gold_badge: challenge.color === 'Gold',
                  teal_badge: challenge.color === 'Teal',
                  light_green_badge: challenge.color === 'Light Green',
                }"
              ></div>
            </div>
            <div style="width: 100%; cursor: pointer">
              <p class="m-0" style="font-weight: 600; font-size: 15px">
                {{ challenge.title }}
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <span class="">{{ challenge.due_date }}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            (isEvents && events.length == 0 && !globalStore.eventsAreLoading) ||
            (!isEvents &&
              activeChallenges.length == 0 &&
              !globalStore.eventsAreLoading)
          "
        >
          <el-empty
            :description="
              isEvents
                ? $t('No events available')
                : $t('No Challenges available')
            "
            style="margin: auto"
            class="empty-image"
          />
        </div>
      </div>
      <CModal
        :show="isConfirmOpen"
        @hide="closeConfirmModal"
        :top="true"
        :draggable="false"
      >
        <template v-slot:header>
          <h3>{{ $t("Confirm Attending") }}</h3>
          <CButton @click="closeConfirmModal">
            <i class="fa fa-times"></i>
          </CButton>
        </template>
        <template v-slot:footer>
          <CButton @click="closeConfirmModal" class="link">{{
            $t("Cancel")
          }}</CButton>
          <CButton @click="confirmAttending" class="modal-confirm" color="info">
            {{ $t("Confirm") }}
          </CButton>
        </template>
        <p>Are you sure to attend this training ?</p>
      </CModal>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapMutations } from "vuex";
import { ContentLoader } from "vue-content-loader";
import { TrainingService } from "@/services/training";
import { mapState } from "vuex";
import { useGlobalStore } from "@/stores/store";
import { ChallengeService } from "@/services/challenges";
import { images } from "../../../../assets/Images/images";

export default {
  name: "CardEvents",
  components: {
    ContentLoader,
  },
  props: {
    allEvents: Array,
    trainings: Array,
    events: Array,
    eventsLoading: Boolean,
    isEvents: Boolean,
  },
  emits: ["getBadgeSvg"],
  data() {
    return {
      loading: false,
      globalStore: useGlobalStore(),
      userName: "",
      isConfirmOpen: false,
      check: false,
      organization: "",
      eventsAreLoading: true,
      project: "",
      label: "",
      allTrainings: [],
      challengesData: [],
      activeChallenges: [],
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
    }),
    images() {
      return images;
    },
  },
  mounted() {
    !this.isEvents && this.getChallengesData();
  },
  watch: {
    eventsLoading(val) {
      this.eventsAreLoading = val;
    },
  },
  methods: {
    getBadgeSvg(badgeId) {
      let img = this.images.challenges[badgeId];
      if (!img) return;
      // Retrieve SVG for the given badge ID
      return img;
    },
    async getChallengesData() {
      try {
        const { data, res } = await ChallengeService.getChallenges();
        this.challengesData = data.data;
        const jobDescriptionKey = Object.keys(
          this.userData.current_job_description
        ).find((key) => key !== "level_id" && key !== "level_name");
        this.challengesData = this.challengesData.filter((challenge) => {
          const usersAccessArray = Array.from(challenge.users_access);

          return usersAccessArray.some((access) => {
            // Check if `access` is an object with the required properties
            if (
              typeof access === "object" &&
              access !== null &&
              "email" in access &&
              "job_description" in access
            ) {
              const emailMatch =
                access.email.trim().toLowerCase() ===
                this.userData.email.trim().toLowerCase();
              const jobDescriptionMatch =
                access.job_description.trim().toLowerCase() ===
                jobDescriptionKey.trim().toLowerCase();

              return emailMatch && jobDescriptionMatch;
            } else {
              return (
                challenge.users_access.includes(this.userData.id) &&
                !challenge.users.includes(this.userData.id)
              );
            }
          });
        });

        // Filter active challenges
        this.activeChallenges = this.challengesData
          .filter((challenge) => challenge.is_active === true)
          .slice(0, 3);
        // Uncomment if needed
        // this.updateDisplayedItems();
      } catch (error) {
        console.error(error);
      } finally {
        // Any cleanup code here
      }
    },
    refreshEverything() {
      const currentDate = new Date();
      const newTrainings = this.allEvents.filter(
        (training) =>
          Array.isArray(training.participants) &&
          training.participants.includes(this.userName) &&
          training.type === "training" &&
          training.project === this.currentProject.name &&
          (training.organization === this.currentOrganization.name ||
            training.organization === this.currentOrganization.organization) &&
          new Date(training.date) >= currentDate
      );
      this.trainings = newTrainings;
      const newEvents = this.allEvents.filter(
        (event) =>
          Array.isArray(event.participants) &&
          event.participants.includes(this.userName) &&
          event.type !== "training" &&
          event.project === this.currentProject.name &&
          (event.organization === this.currentOrganization.name ||
            event.organization === this.currentOrganization.organization) &&
          new Date(event.date) >= currentDate
      );
      this.events = newEvents;
    },
    ...mapMutations(["updateSelectedEvent", "updateSelectedTraining"]),
    goToEvent(event) {
      if (event.type === "training") {
        this.updateSelectedTraining(event);
      } else {
        this.updateSelectedEvent(event);
      }
      this.$router.push("/agenda");
    },

    openConfirmModal(training) {
      this.organization = training.organization;
      this.project = training.project;
      this.label = training.title;
      this.isConfirmOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmOpen = false;
    },
    async confirmAttending() {
      const { res } = await TrainingService.user_attend({
        organization: this.organization,
        project: this.project,
        email: this.email,
        check: !this.check,
        label: this.label,
      });
      if (res.status === 200) {
        showMessage("success", "Attendance confirmed successfully!");
      } else {
        showMessage("error", "Failed to confirm attendance!");
      }
      this.closeConfirmModal();
      this.getListTraining();
    },
    goToChallenge(challenge) {
      this.globalStore.setChallengeToOpen(challenge);
      this.$router.push("/challenges-info");
    },
  },
};
</script>
<style>
.card_event .el-empty__image {
  display: none;
}

@media (max-width: 880px) {
  .card_event .el-empty__image {
    display: unset;
  }
}

.svg-container {
  margin-right: 10px;
  margin-left: 5px;
}

.svg-container svg {
  height: 100%;
  width: 10px;
  margin: 0;
  padding: 0;
  display: block;
  transform: scale(4);
}

@media (max-width: 1280px) {
  .svg-container {
    height: 40px;
    width: fit-content;
  }
  .card--event--container div:first-child {
    gap: 10px !important;
  }

  .card-event-body .card-title {
    font-size: medium;
  }
}

@media (max-width: 800px) {
  .svg-container {
    height: 35px;
    width: fit-content;
  }
}

.card_event {
  overflow-y: scroll;
  border: 1px solid #c3c3c3;
}

.event-card {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 100%;
  cursor: pointer;
}

.event-card:hover {
  background-color: #f9f9f9;
}

.card-title {
  margin: 0;
}

.card--event--container {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.card-event-body {
  gap: 12px;
  overflow: hidden;
  padding: 5px 11px;
}
</style>

<style scoped>
::v-deep .cardBadge.bronze_badge circle {
  fill: #e2a963;
}
::v-deep .cardBadge.bronze_badge path:first-child {
  fill: #de9336;
}

::v-deep .cardBadge.silver_badge circle {
  fill: #d3d3d3;
}
::v-deep .cardBadge.silver_badge path:first-child {
  fill: #b2aeae;
}
::v-deep .cardBadge.teal_badge circle {
  fill: #a2c1bf;
}
::v-deep .cardBadge.teal_badge path:first-child {
  fill: #53908b;
}
::v-deep .cardBadge.light_green_badge circle {
  fill: #dfffe0;
}
::v-deep .cardBadge.light_green_badge path:first-child {
  fill: #7feb45;
}
</style>
