<template>
  <div class="cards-container mb-0 pb-0">
    <div class="content_width h-100 gap-2" style="flex: 0 1 65%; flex-grow: 3">
      <div class="communication--container">
        <CardNews :communications="communications" :newsLoading="newsLoading" />
      </div>
      <div class="events--container">
        <!-- <CardTraining :needToReload="needToReload" /> -->
        <CardEvents
          :allEvents="allEvents"
          :trainings="trainings"
          :events="events"
          :eventsLoading="eventsAreLoading"
          :isEvents="true"
        />
        <CardEvents
          :isEvents="false"
          :allEvents="allEvents"
          :trainings="trainings"
          @getBadgeSvg="getBadgeSvg"
          :events="events"
          :eventsLoading="eventsAreLoading"
        />
      </div>
    </div>

    <div class="right--panel">
      <div class="avatar-progress-container">
        <DefaultAvatar
          :primaryColor="avatar.skinTone"
          :selectedHair="avatar.selectedHair"
          :topClothe="avatar.selectedTopClothes"
          :bottomClothe="avatar.selectedBottomClothes"
          :facialHair="avatar.selectedFacialHair"
          :facialExpression="avatar.selectedExpression"
          :facialHairPosition="facialHairPosition"
          :shoes="avatar.selectedShoes"
        />
        <div class="cards_status" style="">
          <CardStatusMe />
          <CardStatus />
          <CardMyTraining />
          <CardTeamTraining />
        </div>
      </div>

      <div>
        <ContentLoader
          view-box="0 0 500 100"
          v-if="loading"
          :speed="2"
          primaryColor="rgb(230, 230, 230)"
          secondaryColor="rgb(240, 240, 240)"
        ></ContentLoader>
        <div class="w-100" v-else>
          <div v-if="badgeObtained.length > 0">
            <div class="tab-navigation-content">
              <div class="tab-navigation-header">
                <div class="tab-title">
                  <h3 style="margin: 0; padding: 0">
                    {{ tabs[currentTab - 1].title }}
                  </h3>
                </div>
                <div class="tab-navigation">
                  <div
                    @click="prevTab"
                    :class="['nav-btn', { 'disabled-btn': currentTab === 1 }]"
                  >
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.83216 0.158175C7.88537 0.208187 7.92758 0.2676 7.95638 0.33301C7.98518 0.398421 8 0.468543 8 0.539361C8 0.610179 7.98518 0.680301 7.95638 0.745711C7.92758 0.811122 7.88537 0.870534 7.83216 0.920547L1.3801 7.00014L7.83216 13.0797C7.93944 13.1808 7.9997 13.3179 7.9997 13.4609C7.9997 13.6039 7.93944 13.741 7.83216 13.8421C7.72489 13.9432 7.5794 14 7.4277 14C7.27599 14 7.1305 13.9432 7.02323 13.8421L0.167835 7.38133C0.114634 7.33132 0.0724241 7.2719 0.0436241 7.20649C0.0148241 7.14108 0 7.07096 0 7.00014C0 6.92932 0.0148241 6.8592 0.0436241 6.79379C0.0724241 6.72838 0.114634 6.66897 0.167835 6.61896L7.02323 0.158175C7.0763 0.108036 7.13934 0.0682557 7.20874 0.0411135C7.27815 0.0139713 7.35255 0 7.4277 0C7.50284 0 7.57724 0.0139713 7.64665 0.0411135C7.71606 0.0682557 7.7791 0.108036 7.83216 0.158175Z"
                        fill="#434343"
                      />
                    </svg>
                  </div>
                  <div
                    @click="nextTab"
                    :class="[
                      'nav-btn',
                      { 'disabled-btn': currentTab === tabs.length },
                    ]"
                  >
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.167835 0.158175C0.114634 0.208187 0.0724244 0.2676 0.0436244 0.33301C0.0148244 0.398421 0 0.468543 0 0.539361C0 0.610179 0.0148244 0.680301 0.0436244 0.745711C0.0724244 0.811122 0.114634 0.870534 0.167835 0.920547L6.6199 7.00014L0.167835 13.0797C0.0605636 13.1808 0.000299454 13.3179 0.000299454 13.4609C0.000299454 13.6039 0.0605636 13.741 0.167835 13.8421C0.275107 13.9432 0.420599 14 0.572303 14C0.724009 14 0.8695 13.9432 0.976772 13.8421L7.83216 7.38133C7.88537 7.33132 7.92758 7.2719 7.95638 7.20649C7.98518 7.14108 8 7.07096 8 7.00014C8 6.92932 7.98518 6.8592 7.95638 6.79379C7.92758 6.72838 7.88537 6.66897 7.83216 6.61896L0.976772 0.158175C0.923705 0.108036 0.860662 0.0682557 0.791257 0.0411135C0.721851 0.0139713 0.647447 0 0.572303 0C0.49716 0 0.422755 0.0139713 0.35335 0.0411135C0.283945 0.0682557 0.220902 0.108036 0.167835 0.158175Z"
                        fill="#434343"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="tab-content">
                <div v-if="currentTab === 1" class="animated fadeIn">
                  <div
                    class="d-flex flex-row justify-content-center flex-wrap trophies"
                    v-if="badgeObtained.length > 0"
                  >
                    <el-carousel
                      indicator-position="none"
                      height="100px"
                      style="width: 100%; margin-top: 20px"
                    >
                      <el-carousel-item
                        v-for="badge in onboardingBadges.filter((badge) =>
                          isBadgeObtained(badge.name)
                        )"
                        :key="badge.name"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 100%;
                          flex-direction: column;
                          height: 100px;
                        "
                      >
                        <el-tooltip :content="badge.title" effect="dark">
                          <div
                            justify="center"
                            v-html="getBadgeSvg(badge.name)"
                            class="carouselBadge biggerSvg"
                          ></div>
                        </el-tooltip>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
                <div v-if="currentTab === 2" class="animated fadeIn">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: 10px;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        font-weight: bold;
                      "
                    >
                      Individual
                    </p>
                    <div style="display: flex; gap: 20px">
                      <div v-for="badge in userActionsBadges" :key="badge.id">
                        <el-tooltip :content="badge.title" effect="dark">
                          <div
                            v-html="getBadgeSvg(badge.name)"
                            class="carouselBadge"
                            :style="{
                              filter: getActionsObtainedBadge(
                                badge.name,
                                'user'
                              )
                                ? 'none'
                                : 'grayscale(100%)',
                            }"
                          ></div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: 20px;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        font-weight: bold;
                      "
                    >
                      Team
                    </p>
                    <div style="display: flex; gap: 20px">
                      <div v-for="badge in teamActionsBadges" :key="badge.id">
                        <el-tooltip :content="badge.title" effect="dark">
                          <div
                            v-html="getBadgeSvg(badge.name)"
                            class="carouselBadge"
                            :style="{
                              filter: getActionsObtainedBadge(
                                badge.name,
                                'team'
                              )
                                ? 'none'
                                : 'grayscale(100%)',
                            }"
                          ></div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="currentTab === 3" class="animated fadeIn">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: 10px;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        font-weight: bold;
                      "
                    >
                      Individual
                    </p>
                    <div style="display: flex; gap: 20px">
                      <div v-for="badge in userTrainingBadges" :key="badge.id">
                        <el-tooltip :content="badge.title" effect="dark">
                          <div
                            v-html="getBadgeSvg(badge.name)"
                            class="carouselBadge"
                            :style="{
                              filter: getTrainingsObtainedBadge(
                                badge.name,
                                'user'
                              )
                                ? 'none'
                                : 'grayscale(100%)',
                            }"
                          ></div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: 20px;
                    "
                  >
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        font-weight: bold;
                      "
                    >
                      Team
                    </p>
                    <div style="display: flex; gap: 20px">
                      <div v-for="badge in teamTrainingBadges" :key="badge.id">
                        <el-tooltip :content="badge.title" effect="dark">
                          <div
                            v-html="getBadgeSvg(badge.name)"
                            class="carouselBadge"
                            :style="{
                              filter: getTrainingsObtainedBadge(
                                badge.name,
                                'team'
                              )
                                ? 'none'
                                : 'grayscale(100%)',
                            }"
                          ></div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="currentTab === 4" class="animated fadeIn">
                  <div
                    class="d-flex flex-row justify-content-center flex-wrap"
                    v-if="badgeObtained.length > 0"
                  >
                    <el-carousel
                      indicator-position="none"
                      height="auto"
                      style="width: 100%; margin-top: 20px"
                    >
                      <el-carousel-item
                        v-for="item in badgeObtained.filter((item) => {
                          return item.challenge_type != 'default';
                        })"
                        :key="item"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 100%;
                          flex-direction: column;
                          height: 100px;
                        "
                      >
                        <el-tooltip :content="item.title" effect="dark">
                          <div
                            justify="center"
                            v-html="getBadgeSvg(item.badge)"
                            class="carouselBadge biggerSvg"
                            :class="{
                              bronze_badge: item.color === 'Bronze',
                              silver_badge: item.color === 'Silver',
                              gold_badge: item.color === 'Gold',
                              teal_badge: item.color === 'Teal',
                              light_green_badge: item.color === 'Light Green',
                            }"
                          ></div>
                        </el-tooltip>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else style="text-align: center">No badges obtained</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { images } from "../../../assets/Images/images";
import CardEvents from "./content/CardEvent.vue";
import CardStatus from "./content/CardStatus.vue";
import CardNews from "./content/CardNews.vue";
import CardChallenge from "./content/CardChallenge.vue";
import CardTraining from "./content/CardTraining.vue";
import CardStatusMe from "./content/CardStatusMe.vue";
import CardMyTraining from "./content/CardMyTraining.vue";
import CardTeamTraining from "./content/CardTeamTraining.vue";
import DefaultAvatar from "../../profile/DefaultAvatar.vue";
import { useAvatarStore } from "@/stores/AvatarStore";
import { mapWritableState } from "pinia";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Cards",
  props: {
    statusChart: Object,
    activeSlide: Int16Array,
    communications: Array,
    allEvents: Array,
    trainings: Array,
    events: Array,
    newsLoading: Boolean,
    eventsLoading: Boolean,
  },
  components: {
    CardEvents,
    CardStatus,
    CardNews,
    CardTraining,
    CardMyTraining,
    CardTeamTraining,
    // CardChallenge
    CardStatusMe,
    DefaultAvatar,
  },
  data() {
    return {
      eventsAreLoading: true,
      avatarStore: useAvatarStore(),
      badgeObtained: [],

      cardsContent: [
        { id: 1, content: "CardEvents", data: "" },
        { id: 2, content: "CardStatus", data: this.statusChart },
        { id: 3, content: "CardNews", data: "" },
        { id: 4, content: "CardTraining", data: "" },
        { id: 5, content: "CardChallenge", data: "" },
      ],
      tabs: [
        { id: 1, title: "Onboarding" },
        { id: 2, title: "Actions" },
        { id: 3, title: "Training" },
        { id: 4, title: "Challenges" },
      ],
      onboardingBadges: [
        {
          id: 1,
          name: "first_action_by_user_to_self",
          title: "First Action By User To Self",
        },
        {
          id: 2,
          name: "first_action_completed",
          title: "First Action Completed",
        },
        { id: 3, name: "first_avatar_change", title: "First Avatar Change" },
        {
          id: 4,
          name: "first_event_in_agenda",
          title: "First Event In Agenda",
        },
        { id: 5, name: "first_login_to_app", title: "First Login To App" },
        { id: 6, name: "first_news_seen", title: "First News Seen" },
        { id: 7, name: "logged_in_X_times", title: "Logged In X Times" },
      ],
      userActionsBadges: [
        {
          id: 1,
          name: "user_actions_bronze",
          title: "Bronze Actions Badge (user)",
        },
        {
          id: 2,
          name: "user_actions_silver",
          title: "Silver Actions Badge (user)",
        },
        {
          id: 3,
          name: "user_actions_gold",
          title: "Gold Actions Badge (user)",
        },
      ],
      teamActionsBadges: [
        {
          id: 1,
          name: "team_actions_bronze",
          title: "Bronze Actions Badge (team)",
        },
        {
          id: 2,
          name: "team_actions_silver",
          title: "Silver Actions Badge (team)",
        },
        {
          id: 3,
          name: "team_actions_gold",
          title: "Gold Actions Badge (team)",
        },
      ],
      userTrainingBadges: [
        {
          id: 1,
          name: "user_training_bronze",
          title: "Bronze Training Badge (user)",
        },
        {
          id: 2,
          name: "user_training_silver",
          title: "Silver Training Badge (user)",
        },
        {
          id: 3,
          name: "user_training_gold",
          title: "Gold Training Badge (user)",
        },
      ],
      teamTrainingBadges: [
        {
          id: 1,
          name: "training_team_bronze",
          title: "Bronze Training Badge (team)",
        },
        {
          id: 2,
          name: "team_training_silver",
          title: "Silver Training Badge (team)",
        },
        {
          id: 3,
          name: "team_training_gold",
          title: "Gold Training Badge (team)",
        },
      ],
      currentTab: 1,
    };
  },
  watch: {
    eventsLoading(val) {
      this.eventsAreLoading = val;
    },
  },
  async mounted() {
    this.getBadgeObtained();
  },
  computed: {
    ...mapWritableState(useAvatarStore, {
      avatar: "avatar",
      topClothesPosition: "topClothesPosition",
      bottomClothesPosition: "bottomClothesPosition",
    }),
    ...mapState({
      userInfo: (state) => state.userData,
    }),
    images() {
      return images;
    },
  },
  methods: {
    getBadgeSvg(badgeId) {
      let img = this.images.challenges[badgeId];
      if (!img) return;
      // Retrieve SVG for the given badge ID
      return img;
    },
    getBadgeObtained() {
      this.badgeObtained = this.userInfo.challenges;
    },
    prevTab() {
      if (this.currentTab > 1) {
        this.currentTab--;
      }
    },
    nextTab() {
      if (this.currentTab < this.tabs.length) {
        this.currentTab++;
      }
    },
    isBadgeObtained(badge) {
      return this.badgeObtained.some(
        (badgeObj) =>
          badgeObj.badge.toLowerCase() === badge.toLowerCase() &&
          badgeObj.challenge_type === "default"
      );
    },
    getBadgeImagePath(badge) {
      try {
        return require(`@/assets/Images/DefaultBadges/${badge}.png`);
      } catch (error) {
        console.error(`Image not found for badge: ${badge}`);
        return "";
      }
    },
    getActionsObtainedBadge(badge, type) {
      if (type === "user") {
        const userActionsPoints = this.userInfo.total_points;
        const points = userActionsPoints.split(" / ");
        const percentage = (parseInt(points[0]) / parseInt(points[1])) * 100;
        if (percentage >= 100) {
          return true;
        }

        if (percentage >= 66) {
          return badge.includes("silver") || badge.includes("bronze");
        } else if (percentage >= 33) {
          return badge.includes("bronze");
        }
        return false;
      } else {
        const teamActionsData = this.userInfo.level_points_v2.find((role) => {
          return (
            role.job === this.userInfo.current_level_role &&
            role.level === this.userInfo.current_level
          );
        });
        if (!teamActionsData) {
          return false;
        }

        if (teamActionsData.badge.includes("gold")) {
          return true;
        } else if (teamActionsData.badge.includes("silver")) {
          return badge.includes("silver") || badge.includes("bronze");
        } else if (teamActionsData.badge.includes("bronze")) {
          return badge.includes("bronze");
        }
        return false;
      }
    },
    getTrainingsObtainedBadge(badge, type) {
      if (type === "user") {
        const userTrainingPoints = this.userInfo.training_points;
        const currentPoints = userTrainingPoints.current;
        const totalPoints = userTrainingPoints.total;
        const percentage = (currentPoints / totalPoints) * 100;
        if (percentage >= 100) {
          return true;
        }
        if (percentage >= 66) {
          return badge.includes("silver" || badge.includes("bronze"));
        } else if (percentage >= 33) {
          return badge.includes("bronze");
        }
        return false;
      } else {
        const teamTrainingData = this.userInfo.training_points;
        const neededTrainingBadge = teamTrainingData.badge;
        if (!neededTrainingBadge) {
          return false;
        }
        if (neededTrainingBadge.includes("gold")) {
          return true;
        } else if (neededTrainingBadge.includes("silver")) {
          return badge.includes("silver") || badge.includes("bronze");
        } else if (neededTrainingBadge.includes("bronze")) {
          return badge.includes("bronze");
        }
      }
    },
  },
};
</script>

<style scoped>
.biggerSvg {
  scale: 1.4;
}
.cards_status {
  display: flex;
  flex-direction: column;
}

.cards-container {
  display: flex;
  gap: 10px;
  height: calc(100vh - 117px);
}
.events--container {
  height: 35%;
  display: flex;
  gap: 15px;
}
.communication--container {
  height: 65%;
  border: 1px solid #c3c3c3;
}
.right--panel {
  display: flex;
  flex-direction: column;
  flex: 0 1 35%;
  flex-grow: 1;
}
.content_width {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.card-event {
  margin-right: 70px;
}

.content_width {
  flex-basis: 60%;
}

.card-event {
  flex-basis: 40%;
}
.card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 0;
}
.progress-text {
  position: absolute;
  top: 40%;
  margin-left: 28px;
  margin-bottom: 10px;
}
.avatar-progress-container {
  padding: 0 20px;
  display: flex;
  justify-content: space-around;
}
.card-status {
  justify-content: space-evenly;
}
.card-title {
  color: black;
  font-weight: 700;
  font-size: 20px;
}
.status-subtitle {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.team-level {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.card-body {
  padding: 30px;
}
.tab-navigation {
  display: flex;
  gap: 20px;
}
.tab-content {
  margin-top: 20px;
}

.tab-navigation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-navigation-content {
  padding: 0 30px;
}
.nav-btn {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-btn:hover {
  background-color: #e3e1e1;
}

.disabled-btn {
  cursor: not-allowed;
  background-color: #e3e3e3;
  color: #b3b3b3;
  pointer-events: none;
  border: 1px solid #e0e0e0;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
</style>
