<template>
  <CCard class="bg-transparent border-0 shadow-none">
    <CCardBody class="">
      <!-- <CCardText class="card-title">{{ $t("My status") }}</CCardText> -->
      <div class="status-container">
        <div
          class="d-flex gap-2 flex-column text-center"
          v-for="status in statusList"
          :key="status.id"
        >
          <span
            class="mb-0"
            style="
              font-size: 16px !important;

              font-weight: 700 !important;
              color: #434343 !important;
              white-space: nowrap !important;
            "
          >
            {{ $t("Team' action plan") }}
          </span>
          <div class="donut mr-2">
            <el-progress
              type="circle"
              :color="colors"
              class="custom-progress"
              :percentage="
                selectedJob
                  ? Math.round(
                      calculateProgress(
                        userStore.usersTeamProgress?.points || 0
                      )
                    ) == 0
                    ? 1
                    : Math.round(
                        calculateProgress(
                          userStore.usersTeamProgress?.points || 0
                        )
                      )
                  : 1
              "
            >
              <template #default="{ percentage }">
                <span
                  class="percentage-value"
                  :style="
                    'color :' +
                    getFillColor(
                      Math.round(
                        calculateProgress(
                          userStore.usersTeamProgress?.points || 0
                        )
                      )
                    )
                  "
                  >{{
                    Math.round(
                      calculateProgress(
                        userStore.usersTeamProgress?.points || 0
                      )
                    )
                  }}%
                </span>
              </template>
            </el-progress>
          </div>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { UsersService } from "@/services/users";
import { ContentLoader } from "vue-content-loader";
import { mapGetters, mapState } from "vuex";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "CardStatus",
  components: {
    CircleProgress,
    ContentLoader,
  },
  props: {
    needToReload: Boolean,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      includedOrgs: (state) => state.includedOrgs,
      currentOrganization: (state) => state.selectedOrg,
      jobsPoints: (state) => state.jobsPoints,
      selectedJob: (state) => state.selectedJob,
    }),
  },
  data() {
    return {
      userStore: useUserStore(),

      colors: [
        { color: "#E40303", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#2EB85C", percentage: 100 },
      ],
      allJobDescriptions: [],
      showOptions: false,
      loading: true,
      selectedTeam: null,
      teams: [],
      statusList: [
        {
          id: 1,
          name: "Actions plan",
          share: "",
          key: "team",
        },
      ],
    };
  },
  watch: {
    selectedTeam(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateTeamProgress();
      }
    },
  },
  async created() {
    const user = this.userData;
    this.allJobDescriptions = this.userData.level_points;
    this.teams = user.average_teams_advancement;
    this.selectedTeam = this.teams[0].organization_name;

    this.statusList.forEach((status) => {
      if (status.key === "team") {
        status.share = this.actionsPercentage;
      } else {
        status.share = 0;
      }
    });

    this.loading = false;
  },
  methods: {
    calculateProgress(str) {
      if (typeof str !== "string" || str.trim() === "") {
        return 0;
      }
      const parts = str.split(" / ");
      if (parts.length === 2) {
        const current = parseFloat(parts[0]);
        const total = parseFloat(parts[1]);
        if (total === 0) {
          return 0;
        }

        const percentage = (current / total) * 100;
        return percentage;
      }
      return 0;
    },

    async jobSelected(job) {
      this.$store.commit("setJob", job);
      this.showOptions = false;
    },

    refreshEverything() {},
    toggleOptions() {
      this.showOptions = true;
    },
    async updateTeamProgress() {
      try {
        const selectedTeamData = this.teams.find(
          (team) => team.organization_name === this.selectedTeam
        );
        if (selectedTeamData) {
          this.statusList.forEach((status, index) => {
            if (status.name === "Team actions plan") {
              status.share = selectedTeamData.value;
            }
          });
        }
      } catch (error) {
        console.error("Error updating team progress", error);
      }
    },
    getFillColor(share) {
      if (share < 20) {
        return "#E40303";
      } else if (share < 40) {
        return "#e6a23c";
      } else if (share < 60) {
        return "#5cb87a";
      } else if (share < 80) {
        return "#1989fa";
      } else if (share < 100 || share == 100) {
        return "#2EB85C";
      }
    },
  },
};
</script>

<style scoped>
.orgProjectOption {
  padding: 10px;
  cursor: pointer;
}
.orgProjectOption:hover {
  background: #f5f5f5;
}
.orgProjectOption:active {
  background: #e6e6e6;
}
.status-container {
  gap: 20px;
  justify-content: center;
}
.donut {
  margin-top: 5px;
  position: relative;
}
.donut-text {
  bottom: 30%;
  text-align: center;
}
.donut-chart {
  color: green;
  position: absolute;
}
.percentage-value {
  font-weight: 700;
}
::v-deep .el-progress-circle {
  width: 90px !important;
  height: 90px !important;
}

::v-deep .card-body {
  padding: 3px 0px !important;
}

::v-deep .custom-progress .el-progress-circle__track {
  stroke: #e9e9e9;
}
</style>
